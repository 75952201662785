#deposit-card {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .price {
    max-width: 50%;
    text-align: end;
  }

  .form input {
    height: 40px;
    width: 100%;
    padding: 0 19px;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    color: #030306;
    border-radius: 0;
    transition: 0.2s;
    font-size: 15px;
    line-height: 1.07;
  }

  .terms {
    margin-top: 10px;

    .text {
      font-size: 12px;
      display: flex;
      justify-content: center;

      .name {
        text-align: center;
        width: 100%;
      }

      a {
        text-align: center;
        width: 100%;
      }
    }
  }

  .summary {
    padding-top: 20px;
    margin-top: 15px;
    border-top: 1px solid #e4e4e4;
    font-size: 16px !important;
    .item {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sum {
      font-weight: bold;
    }

    &.ready {
      a {
        color: #f75e5e;
      }
      &:first-child {
        border-top: none;
      }
    }
  }

  .row {
    display: flex;
    width: 100%;

    .choose-form {
      padding: 0 40px !important;

      &:first-child {
        border-right: 2px solid #dbdbdb;
      }
    }

    .col {
      display: flex;
      width: 50%;
      flex-direction: column;

      &:first-child {
        padding-right: 10px;
      }

      // &:last-child {
      //   padding-left: 10px;
      // }
    }
  }

  .col-def {
    display: flex;
    width: 50%;
    flex-direction: column;
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }

  .add {
    width: 50px;
    height: 37px;
    border-radius: 2px;
    background-color: #f75e5e;
    border: 0;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    transition: 0.2s;
    cursor: pointer;
  }

  .form-group {
    display: block;
    width: 100%;
    margin-bottom: 5px;

    label {
      display: block;
      width: 100%;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #030306;
      margin-bottom: 5px;
    }

    input {
      width: 100%;
      border: 1px solid #dbdbdb;
      background-color: #ffffff;
      height: 37px;
      padding: 0 16px;

      &:focus {
        outline: 0;
      }

      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2a2f32;
    }

    textarea {
      width: 100%;
      border: 1px solid #dbdbdb;
      background-color: #ffffff;
      min-height: 70px;
      padding: 0 16px;

      &:focus {
        outline: 0;
      }

      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2a2f32;
    }

    .edit-phone {
      width: 100%;
      height: 37px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 37px;
      letter-spacing: normal;
      color: #2a2f32;
    }
  }

  section {
    &:first-child {
      padding-top: 50px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;

    &.mb10 {
      margin-bottom: 10px !important;
    }

    .caption {
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.01px;
      color: #2a2f32;
      margin-bottom: 10px;
    }

    .signature {
      margin-top: 20px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #2a2f32;

      i {
        margin-right: 5px;
      }
    }
  }

  .places-content {
    padding: 0 100px;
    width: 1150px;
    margin-bottom: 40px;

    .places {
      display: flex;
      align-items: center;
      justify-content: center;

      .place {
        float: left;
        margin: 0 10px 30px;
        position: relative;
        cursor: pointer;

        width: calc((100% / 4) - 20px);
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          a {
            width: calc(100% - 22px);
            height: calc(100% - 19px);
            transition: 0.3s;
          }
        }

        &.active {
          a {
            width: calc(100% - 22px);
            height: calc(100% - 19px);
            transition: 0.3s;
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 2px solid #f75e5e;
          border-radius: 28px;
          z-index: 0;
        }

        a {
          font-size: 0;
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 28px;

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: 0.3s;
          z-index: 1;
          box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);

          &.bg-1 {
            background-image: url("../images/places/empty/1.png");
          }

          &.bg-4 {
            background-image: url("../images/places/empty/4.png");
          }

          &.bg-6 {
            background-image: url("../images/places/empty/6.png");
          }

          &.bg-8 {
            background-image: url("../images/places/empty/8.png");
          }

          &.bg-9 {
            background-image: url("../images/places/empty/9.png");
          }

          &.bg-10 {
            background-image: url("../images/places/empty/10.png");
          }

          &.bg-11 {
            background-image: url("../images/places/empty/11.png");
          }
          &.bg-20 {
            background-image: url("../images/places/empty/20.png");
          }
          &.bg-13 {
            background-image: url("../images/places/empty/13.png");
          }

          &.bg-16 {
            background-image: url("../images/places/empty/16.png");
          }

          &.bg-17 {
            background-image: url("../images/places/empty/17.png");
          }

          &.bg-18 {
            background-color: #000000;
            overflow: hidden;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              &::before {
                width: calc(138px - 28px);
                height: calc(138px - 24px);
                transition: 0.3s;
              }
            }

            &::after {
              content: "";
              opacity: 0.6;
              z-index: 0;

              position: absolute;
              top: 0;
              left: 0;

              background-image: url("../images/places/extra/bg-18.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              display: block;
              width: 100%;
              height: 100%;
            }

            &::before {
              content: "";
              display: block;
              z-index: 1;

              width: 138px;
              height: 138px;
              background-image: url("../images/places/extra/l-18.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              transition: 0.3s;
            }
          }

          &.bg-19 {
            background-color: #000000;
            overflow: hidden;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              &::before {
                width: calc(138px - 28px);
                height: calc(138px - 24px);
                transition: 0.3s;
              }
            }

            &::after {
              content: "";
              opacity: 0.6;
              z-index: 0;

              position: absolute;
              top: 0;
              left: 0;

              background-image: url("../images/places/extra/bg-19.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;

              display: block;
              width: 100%;
              height: 100%;
            }

            &::before {
              content: "";
              display: block;
              z-index: 1;

              width: 138px;
              height: 138px;
              background-image: url("../images/places/extra/l-19.png");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              transition: 0.3s;
            }
          }
        }
      }

      .fix {
        width: calc((100% / 3) - 20px);
        margin: 0 10px 30px;
        display: flex;
        flex-direction: column;
        font-size: 20px;

        .error {
          font-size: 10px;
          color: #f75e5e;
        }

        .bttns {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            border: none;
            text-align: center;
            max-width: 85px;
            font-size: 25px;
          }

          button {
            border: none;
            background-color: #fff;
          }
        }
      }

      .place-card {
        float: left;
        margin: 0 10px 15px;
        position: relative;

        width: calc((100% / 3) - 20px);
        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          a {
            width: calc(100% - 28px);
            height: calc(100% - 24px);
            transition: 0.3s;
            font-size: 22px;
          }
        }

        &.active {
          .innards,
          a {
            width: calc(100% - 28px);
            height: calc(100% - 24px);
            transition: 0.3s;
            font-size: 22px;
          }
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block;
          border: 2px solid #f75e5e;
          border-radius: 15px;
          z-index: 0;
        }

        a {
          font-size: 25px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;

          border-radius: 15px;
          text-decoration: none;

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: 0.3s;
          z-index: 1;
          box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);

          background-color: #f75e5e;
          color: #fff;
          cursor: pointer;

          input,
          button {
            background-color: #f75e5e !important;
          }
        }

        .innards {
          font-size: 30px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          border-radius: 15px;
          text-decoration: none;

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: 0.3s;
          z-index: 1;
          box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);

          background-color: #f75e5e;
          color: #fff;

          input,
          button {
            background-color: #f75e5e !important;
          }
        }
      }

      .money-card {
        float: left;
        margin: 0 10px 30px;
        position: relative;
        padding: 20px;

        height: 165px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #f75e5e;
        border-radius: 15px;
        flex-direction: column;

        float: left;
        margin: 0 10px 15px;
        position: relative;

        width: calc((100% / 3) - 20px);
        // height: 230px;
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);

        a {
          font-size: 25px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          text-decoration: none;

          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: 0.3s;
          z-index: 1;
          box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);

          background-color: #f75e5e;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  #how-to-use {
    padding: 0 100px 40px 100px;
    width: 1150px;

    .terms * {
      font-size: 14px;
      text-align: left;
      margin-bottom: 10px;
    }

    .signature {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
    }

    .table {
      margin-bottom: 20px;
      .table-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        i {
          font-size: 30px;
        }

        .round {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f75e5e;
          color: #fff;
          border-radius: 50%;
          font-size: 16px;
        }

        .text {
          width: calc(100% - 40px);
        }
      }
    }

    &.now-to-use {
      padding: 0;
      width: auto;
      border-bottom: 1px solid #e4e4e4;
    }
  }

  .profile-form {
    width: 100%;
    padding: 0 0 50px;
    // border-bottom: 1px solid #e4e4e4;

    .i-agree-container {
      padding-bottom: 5px;

      .checkbox .container {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2a2f32;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      padding-top: 10px;

      button {
        width: 477px;
        height: 40px;
        border-radius: 2px;
        background-color: #f75e5e;
        border: 0;

        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          background-color: #e84747;
          transition: 0.2s;
        }

        &:disabled {
          background-color: #e4e4e4;
        }
      }
    }
  }
}

.default-mobile-layout {
  #deposit-card {
    #how-to-use {
      padding: 0 40px 40px;
      width: auto;

      &.now-to-use{
        padding: 0;
      }
    }  
    .button-container {
      padding: 0 50px;
    }

    .check {
      padding: 0 10px;
    }

    .col {
      width: 100%;
      margin: 0 15px;
    }

    .col-def {
      display: flex;
      width: 50%;
      flex-direction: column;
      margin-right: 5px;

      &:last-child {
        margin: 0;
      }
    }

    section {
      width: 100%;
    }

    .places-content {
      width: 100% !important;
      padding: 0;

      .places {
        .fix {
          width: 80%;
          margin: 0;

          .money-card {
            height: 175px;
            margin: 0 0px 15px;
          }
        }

        .place {
          width: 80%;
        }

        .place-card {
          width: 80%;
          height: 175px;
          margin: 0 0 15px;
        }
      }
    }
  }
}
