.layout-bonus-program {
    position: relative;

    &::after {
        content: "";
        width: 351px;
        height: 367px;
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url(../images/percent.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.bonus-program {
    display: flex;
    flex-direction: column;
    width: 1240px;
    margin: 0 auto;

    .places-content {
        margin-bottom: 27px;

        .places {
            display: flex;
            align-items: center;
            justify-content: center;
            
            .place {
                float: left;
                margin: 0 8px 27px;
                position: relative;
                cursor: pointer;
    
                width: calc((100% / 4) - 18px);
                height: 220px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
    
                &.selected,
                &:hover {
                    > div.bg {
                        width: calc(100% - 25px);
                        height: calc(100% - 22px);
                        transition: .3s;
                    }
                }
    
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    border: 2px solid #f75e5e;
                    border-radius: 35px;
                    z-index: 0;
                }

                .description {
                    position: absolute;
                    left: 0;
                    top: 0;

                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.89;
                    letter-spacing: normal;
                    color: #fff;

                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 1;

                    div.logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 135px;
                        margin-bottom: 4px;

                        &::before {
                            content: "";
                            display: block;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
    
                        &.l-1 {
                            &::before {
                                width: 103px;
                                height: 117px;
                                background-image: url("../images/places/bonus/l-1.svg");
                            }
                        }
                        &.l-4 {
                            &::before {
                                width: 146px;
                                height: 108px;
                                background-image: url("../images/places/bonus/l-4.svg");
                            }
                        }
                        &.l-6 {
                            &::before {
                                width: 203px;
                                height: 98px;
                                background-image: url("../images/places/bonus/l-6.svg");
                            }
                        }
                        &.l-8 {
                            &::before {
                                width: 203px;
                                height: 105px;
                                background-image: url("../images/places/bonus/l-8.svg");
                            }
                        }
                        &.l-9 {
                            &::before {
                                width: 166px;
                                height: 89px;
                                background-image: url("../images/places/bonus/l-9.svg");
                            }
                        }
                        &.l-20 {
                            &::before {
                                width: 240px;
                                height: 89px;
                                background-image: url("../images/places/bonus/l-20.svg");
                            }
                        }
                        &.l-10 {
                            &::before {
                                width: 103px;
                                height: 112px;
                                background-image: url("../images/places/bonus/l-10.svg");
                            }
                        }
                        &.l-11 {
                            &::before {
                                width: 124px;
                                height: 117px;
                                background-image: url("../images/places/bonus/l-11.svg");
                            }
                        }
                        &.l-13 {
                            &::before {
                                width: 107px;
                                height: 135px;
                                background-image: url("../images/places/bonus/l-13.svg");
                            }
                        }
                        &.l-16 {
                            &::before {
                                width: 158px;
                                height: 80px;
                                background-image: url("../images/places/bonus/l-16.png");
                            }
                        }
                        &.l-17 {
                            &::before {
                                width: 124px;
                                height: 125px;
                                background-image: url("../images/places/bonus/l-17.png");
                            }
                        }
                        
                        &.l-18 {
                            &::before {
                                width: 124px;
                                height: 125px;
                                background-image: url("../images/places/extra/l-18.png");
                            }
                        }
                        
                        &.l-19 {
                            &::before {
                                width: 124px;
                                height: 125px;
                                background-image: url("../images/places/extra/l-19.png");
                            }
                        }
                    }
                }
    
                > div.bg {
                    width: 100%;
                    height: 100%;
                    border-radius: 35px;
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: .3s;
                    z-index: 1;
                    box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);
                    
                    &.bg-1 {
                        background-image: url("../images/places/bonus/bg-1.png");
                    }
                    &.bg-4 {
                        background-image: url("../images/places/bonus/bg-4.png");
                    }
                    &.bg-6 {
                        background-image: url("../images/places/bonus/bg-6.png");
                    }
                    &.bg-8 {
                        background-image: url("../images/places/bonus/bg-8.png");
                    }
                    &.bg-9 {
                        background-image: url("../images/places/bonus/bg-9.png");
                    }
                    &.bg-10 {
                        background-image: url("../images/places/bonus/bg-10.png");
                    }
                    &.bg-11 {
                        background-image: url("../images/places/bonus/bg-11.png");
                    }
                    &.bg-13 {
                        background-image: url("../images/places/bonus/bg-13.png");
                    }
                    &.bg-16 {
                        background-image: url("../images/places/bonus/bg-16.png");
                    }
                    &.bg-20 {
                        background-image: url("../images/places/bonus/bg-20.png");
                    }
                    &.bg-17 {
                        background-image: url("../images/places/bonus/bg-17.png");
                    }

                    &.bg-18 {
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 100%;
                            background: #000000;
                            display: block;
                        }

                        &::before {
                            position: absolute;
                            left: 0;
                            top: 0;

                            content: "";
                            background-image: url("../images/places/extra/bg-18.jpg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;

                            opacity: .6;
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    &.bg-19 {
                        overflow: hidden;
                        position: relative;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 100%;
                            background: #000000;
                            display: block;
                        }

                        &::before {
                            position: absolute;
                            left: 0;
                            top: 0;

                            content: "";
                            background-image: url("../images/places/extra/bg-19.jpg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;

                            opacity: .6;
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .description-card {
        width: 1240px;
        margin: 0 auto 27px;
        display: flex;
        flex-direction: column;

        .card-info {
            padding: 45px 135px;
            background-color: #dbdbdb;
            border-radius: 35px;

            .place-name {
                font-size: 33px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.62;
                letter-spacing: normal;
                color: #2a2f32;
                margin-bottom: 27px;
            }

            .card-number {
                font-size: 18px;
                font-weight: bold;
                color: #2a2f32;
                margin-bottom: 27px;
            }

            .info-box {
                width: 100%;
                height: 185px;
                display: flex;
                flex-direction: column;
                padding: 0 27px;
                align-items: center;
                justify-content: center;

                background-color: #ffffff;

                .caption {
                    font-size: 28px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    margin-bottom: 8px;
                    color: #2a2f32;

                    &.red-color {
                        color: #f75e5e;
                    }
                }

                .signature {
                    font-size: 16px;
                    text-align: center;
                    color: #2a2f32;
                }
            }

            .link-offer {
                margin-top: 45px;
                a {
                    font-size: 16px;
                    color: #2a2f32;
                    text-decoration: none;
                    border-bottom: 1px dashed #f75e5e;
                    transition: .2s;

                    &:hover {
                        color: #f75e5e;
                        transition: .2s;
                        border-color: #f75e5e;
                    }
                }
            }
        }

        .add-card {
            width: 1240px;
            margin: 36px auto;
            padding: 0 90px;

            .caption {
                font-size: 18px;
                font-weight: bold;
                color: #2a2f32;
            }

            .buttons {
                margin-top: 27px;
                button {
                    display: block;
                    border: 0;
                    float: left;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 32px;
                    cursor: pointer;
                    opacity: 1;
                    transition: .3s;
                    background-color: #ffffff;
                    
                    &:hover {
                        opacity: .5;
                        transition: .3s;
                    }

                    &.gpay {
                        width: 43px;
                        height: 43px;
                        background-image: url(../images/gpay.jpg);
                    }

                    &.wallet {
                        width: 43px;
                        height: 43px;
                        background-image: url(../images/wallet.png);
                    }
                }
            }
        }
    }
}
.default-mobile-layout {
    .bonus-program {
        margin-top: 18px;

        .places {
            flex-direction: column;
     
            .place {
                 width: calc(100% - 18px);
                 margin: 0 9px 9px;
            }
        }
    }

    .description-card {
        width: 100%;
        margin: 18px auto 18px;

        .card-info {
            padding: 36px 13px 36px;
            margin: 0 9px;

            .place-name {
                font-size: 33px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.62;
                letter-spacing: normal;
                color: #2a2f32;
                margin-bottom: 13px;
                text-align: center;
            }

            .card-number {
                font-size: 18px;
                font-weight: bold;
                color: #2a2f32;
                margin-bottom: 13px;
                text-align: center;
            }

            .info-box {
                width: 100%;
                height: 100px;
                display: flex;
                flex-direction: column;
                padding: 0 27px;
                align-items: center;
                justify-content: center;
                margin-bottom: 9px;

                background-color: rgba(#dbdbdb, .3);

                .caption {
                    font-size: 28px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    text-align: center;
                    margin-bottom: 9px;
                    color: #2a2f32;

                    &.red-color {
                        color: #f75e5e;
                    }
                }

                .signature {
                    font-size: 16px;
                    text-align: center;
                    color: #2a2f32;
                }
            }

            .link-offer {
                margin-top: 9px;
                text-align: center;
                a {
                    font-size: 13px;
                    color: #2a2f32;
                    text-decoration: none;
                    border-bottom: 1px dashed #f75e5e;
                    transition: .2s;

                    &:hover {
                        color: #f75e5e;
                        transition: .2s;
                        border-color: #f75e5e;
                    }
                }
            }
        }

        .add-card {
            width: 100%;
            margin: 18px auto;
            padding: 0 27px;
            text-align: center;

            .caption {
                font-size: 18px;
                font-weight: bold;
                color: #2a2f32;
            }

            .buttons {
                margin: 27px auto 0;
                display: inline-block;

                button {
                    display: block;
                    border: 0;
                    float: left;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 32px;
                    cursor: pointer;
                    opacity: 1;
                    transition: .3s;
                    background-color: #ffffff;
                    
                    &:hover {
                        opacity: .5;
                        transition: .3s;
                    }

                    &.gpay {
                        width: 43px;
                        height: 43px;
                        background-image: url(../images/gpay.jpg);
                    }

                    &.wallet {
                        width: 43px;
                        height: 48px;
                        background-image: url(../images/wallet.png);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}