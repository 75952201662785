.current-order {
    display: flex;
    padding: 0 100px;

    width: 1240px;
    margin: 0 auto;

    .information {
        width: 342px;
        > div {
            display: inline-block;
            border-left: 4px solid #f75e5e;
            padding: 18px 63px 11px 36px;

            > .caption {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: #2a2f32;
                margin-bottom: 18px;
            }
        }

        .information-row {
            margin-bottom: 18px;

            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            .caption {
                color: #818182;
            }
            .value {
                color: #2a2f32;
                a {
                    color: #2a2f32;
                }
            }
        }
    }

    .products {
        width: calc(100% - 342px);

        
        table {
            width: 100%;
            margin-bottom: 27px;
            border-collapse: collapse;

            tr {
                td.separator {
                    height: 18px;
                }
                td {
                    &.td-image {
                        width: 112px;
                    }
                    .image {
                        width: 100%;
                        height: 75px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                .name, .amount, .price {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #2a2f32;
                }

                .name {
                    padding-left: 36px;
                }

                .amount {
                    text-align: center;
                    font-weight: bold;
                    padding: 0 27px;
                }

                .price {
                    font-weight: bold;
                    text-align: right;
                    width: 90px;
                }
            }

            .total-sum, .discount, .sum {
                border-top: 1px solid #dbdbdb;
                text-align: right!important;
                padding: 11px 0;

                font-size: 17px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #2a2f32;

                span {
                    padding-left: 11px;
                    font-size: 27px;
                }
            }

            .sum {
                font-weight: bold;
            }
        }

        .cancel-order {
            text-align: right;
            
            button {
                width: 270px;
                height: 36px;
                border-radius: 2px;
                background-color: #f75e5e;
                border: 0;
                outline: 0;
                cursor: pointer;
                transition: .2s;

                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #ffffff;

                &:focus {
                    outline: 0;
                    box-sizing: 0;
                    background-color: #f75e5e;
                    transition: .2s;
                }
        
                &:hover {
                    background-color: #e84747;
                    transition: .2s;
                }
            }
        }
    }
}

.default-mobile-layout {
    .current-order {
        display: flex;
        padding: 18px 22px;
        width: 100%;
        margin: 0;
        flex-direction: column;

        .information {
            width: 100%;
            > div {
                display: inline-block;
                border-left: 0;
                padding: 0 0 18px 0;
                border-bottom: 4px solid #f75e5e;
                margin-bottom: 18px;

                > .caption {
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.15;
                    letter-spacing: normal;
                    color: #2a2f32;
                    margin-bottom: 18px;
                }
            }

            .information-row {
                margin-bottom: 18px;

                font-size: 16px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                .caption {
                    color: #818182;
                }
                .value {
                    color: #2a2f32;
                }
            }
        }

        .products {
            width: 100%;
            table {
                width: 100%;
                margin-bottom: 16px;
                border-collapse: collapse;

                tr {
                    td.separator {
                        height: 20px;
                    }

                    .name, .amount, .price {
                        font-size: 12px;
                    }

                    .name {
                        padding-left: 0;
                    }
                }

                .total-sum, .discount, .sum {
                    border-top: 1px solid #dbdbdb;
                    text-align: right!important;
                    padding: 11px 0;

                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #2a2f32;

                    span {
                        padding-left: 9px;
                        font-size: 16px;
                    }
                }

                .sum {
                    font-weight: bold;
                }
            }

            .cancel-order {
                text-align: right;
                
                button {
                    width: 270px;
                    height: 36px;
                    border-radius: 2px;
                    background-color: #f75e5e;
                    border: 0;
                    outline: 0;
                    cursor: pointer;
                    transition: .2s;

                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    color: #ffffff;

                    &:focus {
                        outline: 0;
                        box-sizing: 0;
                        background-color: #f75e5e;
                        transition: .2s;
                    }
            
                    &:hover {
                        background-color: #e84747;
                        transition: .2s;
                    }
                }
            }
        }
    }
}