#profile {
    display: block;

    width: 1240px;
    margin: 0 auto;

    .row {
        display: flex;
        width: 100%;

        .col {
            display: flex;
            width: calc(50%);
            flex-direction: column;

            &:first-child {
                padding-right: 9px;
            }
            &:last-child {
                padding-left: 9px;
            }
        }
    }

    .profile-form {
        width: 100%;
        padding: 0 100px 50px;
        border-bottom: 1px solid #e4e4e4;

        .form-group {
            display: block;
            width: 100%;
            margin-bottom: 18px;

            label {
                display: block;
                width: 100%;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.23;
                letter-spacing: normal;
                color: #030306;
                margin-bottom: 9px;
            }

            input {
                width: 100%;
                border: 1px solid #dbdbdb;
                background-color: #ffffff;
                height: 33px;
                padding: 0 14px;

                &:focus {
                    outline: 0;
                }

                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
            }

            .edit-phone {
                width: 100%;
                height: 33px;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 33px;
                letter-spacing: normal;
                color: #2a2f32;
            }
        }

        .i-agree-container {
            padding-top: 13px;
            padding-bottom: 18px;

            .checkbox .container {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
            }
        }

        .button-container {
            button {
                width: 429px;
                height: 36px;
                border-radius: 2px;
                background-color: #f75e5e;
                border: 0;

                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
                transition: .2s;
                cursor: pointer;

                &:hover {
                    background-color: #e84747;
                    transition: .2s;
                }

                &:disabled {
                    background-color: #e4e4e4;
                }
            }
        }
    }

    .delivery-addresses {
        padding: 36px 90px 45px;

        .caption {
            font-size: 18px;
            font-weight: bold;
            color: #2a2f32;
            margin-bottom: 27px;
        }

        .new-addresses {
            form {
                width: 100%;

                input {
                    width: 100%;
                    border: 1px solid #dbdbdb;
                    background-color: #ffffff;
                    height: 33px;
                    padding: 0 14px;
    
                    &:focus {
                        outline: 0;
                    }
    
                    font-size: 13px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #2a2f32;

                    margin-bottom: 9px;
                }

                button {
                    margin-top: 18px;
                    width: 429px;
                    height: 36px;
                    border-radius: 2px;
                    background-color: #f75e5e;
                    border: 0;
    
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                    transition: .2s;
                    cursor: pointer;
    
                    &:hover {
                        background-color: #e84747;
                        transition: .2s;
                    }
    
                    &:disabled {
                        background-color: #e4e4e4;
                    }
                }
            }
        }

        .saved-addresses {
            ul {
                margin: 0 0 45px 0;
                padding: 0;

                li {
                    list-style-type: none;

                    display: flex;
                    align-items: center;
                    margin-bottom: 13px;

                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #2a2f32;

                    button {
                        margin-left: 22px;
                        background-color: #ffffff;
                        border: 0;
                        color: #f75e5e;
                        cursor: pointer;
                        transition: .2s;

                        &:focus {
                            outline: 0;
                        }

                        &:hover {
                            color: #e84747;
                            transition: .2s;
                        }
                    }
                }
            }
        }
    }
}

.default-mobile-layout {
    #profile {
        display: block;
    
        width: 100%;
        margin: 0 22px;
    
        .row {
            display: flex;
            width: 100%;
    
            .col {
                display: flex;
                width: 100%;
                flex-direction: column;
    
                &:first-child {
                    padding-right: 0;
                }
                &:last-child {
                    padding-left: 0;
                }
            }
        }
    
        .profile-form {
            width: 100%;
            padding: 18px 0 27px;
            border-bottom: 1px solid #e4e4e4;
    
            .form-group {
                display: block;
                width: 100%;
                margin-bottom: 10px;
    
                label {
                    display: block;
                    width: 100%;
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.13;
                    letter-spacing: normal;
                    color: #818182;
                    margin-bottom: 4px;
                }
    
                input {
                    width: 100%;
                    border: 1px solid #dbdbdb;
                    background-color: #ffffff;
                    height: 33px;
                    padding: 0 14px;
    
                    &:focus {
                        outline: 0;
                    }
    
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #2a2f32;
                }
    
                .edit-phone {
                    width: 100%;
                    height: 33px;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 33px;
                    letter-spacing: normal;
                    color: #2a2f32;
                }
            }
    
            .i-agree-container {
                padding-top: 13px;
                padding-bottom: 18px;
    
                .checkbox .container {
                    font-size: 11px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #2a2f32;
                }
            }
    
            .button-container {
                button {
                    width: 100%;
                    height: 36px;
                    border-radius: 2px;
                    background-color: #f75e5e;
                    border: 0;
    
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                    transition: .2s;
                    cursor: pointer;
    
                    &:hover {
                        background-color: #e84747;
                        transition: .2s;
                    }
    
                    &:disabled {
                        background-color: #e4e4e4;
                    }
                }
            }
        }
    
        .delivery-addresses {
            width: 100%;
            padding: 18px 0;
    
            .caption {
                font-size: 18px;
                font-weight: bold;
                color: #2a2f32;
                margin-bottom: 18px;
            }
    
            .new-addresses {
                form {
                    width: 100%;
    
                    input {
                        width: 100%;
                        border: 1px solid #dbdbdb;
                        background-color: #ffffff;
                        height: 41px;
                        padding: 0 14px;
        
                        &:focus {
                            outline: 0;
                        }
        
                        font-size: 13px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #2a2f32;
    
                        margin-bottom: 9px;
                    }
    
                    button {
                        margin-top: 9px;
                        width: 100%;
                        height: 36px;
                        border-radius: 2px;
                        background-color: #f75e5e;
                        border: 0;
        
                        font-size: 18px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #ffffff;
                        transition: .2s;
                        cursor: pointer;
        
                        &:hover {
                            background-color: #e84747;
                            transition: .2s;
                        }
        
                        &:disabled {
                            background-color: #e4e4e4;
                        }
                    }
                }
            }
    
            .saved-addresses {
                ul {
                    margin: 0 0 27px 0;
                    padding: 0;
    
                    li {
                        list-style-type: none;
    
                        display: flex;
                        align-items: center;
                        margin-bottom: 13px;
    
                        font-size: 13px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #2a2f32;
    
                        button {
                            margin-left: 9px;
                            background-color: #ffffff;
                            border: 0;
                            color: #f75e5e;
                            cursor: pointer;
                            transition: .2s;
    
                            &:focus {
                                outline: 0;
                            }
    
                            &:hover {
                                color: #e84747;
                                transition: .2s;
                            }
                        }
                    }
                }
            }
        }
    }
}