@import "~normalize.css";
@import "~rsuite/dist/styles/rsuite-default.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~rodal/lib/rodal.css";
@import "~react-alice-carousel/lib/alice-carousel.css";
// Перенести
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
//
@import "./spacings.css";
//
@import "./layouts/authorization.scss";
@import "./layouts/default.scss";

@import "./pages/currentOrder.scss";
@import "./pages/currentOrderEmpty.scss";
@import "./pages/historyOrder.scss";
@import "./pages/profile.scss";
@import "./pages/bonusProgram.scss";
@import "./pages/depositCard.scss";

@import "./components/form.scss";
@import "./components/checkbox.scss";
@import "./components/changePasswordModal.scss";

html,
body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.app-loading {
  padding: 45px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-block {
  background-color: #fff;
  box-shadow: 0 1px rgba(0, 0, 0, 0.03);
  margin-bottom: 23px;
  padding: 25px;
  margin: 0 auto;
}

.alert-block .title {
  font-size: 27px;
  font-weight: bold;
  color: #272c36;
  margin-bottom: 10px;
  text-align: center;
}

.alert-block .description {
  font-size: 14px;
  text-align: center;
}

.alert-block .icon {
  font-size: 54px;
  color: #9ccc65;
}

.alert-block .icon-container {
  width: 90px;
  height: 90px;
  background-color: #ebf5df;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-block .icon-container.warning {
  background-color: #fcf7e6;
}

.alert-block .icon-container.warning .icon {
  color: #ffca28;
}

.alert-block .icon-container.danger {
  background-color: #fae9e8;
}

.alert-block .icon-container.danger .icon {
  color: #f75e5e;
}

.rodal-close {
  width: 16px;
  height: 16px;
  top: 9px;
  right: 9px;

  &:before,
  &:after {
    height: 3px;
    border-radius: 0;
    background-color: #2a2f32;
  }
}

.rodal {
  &.mobile {
    .rodal-dialog {
      width: 90% !important;
    }
  }
}

.black {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1e232c;
}

.divider {
  height: 2px;
  width: 15vw;
  background-color: #f75e5e;
  margin: 0 auto;
  margin-bottom: 18px;
}

.app-store {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  flex-direction: column;

  .kasha {
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #2a2f32;
    margin-bottom: 10px;
  }

  .app-strore-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    a.app-strore {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.app-strore-left {
        justify-content: flex-start;
      }

      .bage {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 150px;
        height: 30px;
        margin-bottom: 20px;

        &.google {
          background-image: url("images/google-play-badge-black.png");
          background-position: right;
          margin-right: 10px;
        }

        &.apple {
          justify-content: flex-start;
          background-image: url("images/appstore-black.png");
          background-position: left;
          width: 135px;
        }
      }
    }
  }
}

.default-mobile-layout {
  h6 {
    text-align: center;
    margin: 10px 0;
    font-size: 18px;
  }

  .app-store {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    flex-direction: column;

    .kasha {
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #2a2f32;
      margin-bottom: 10px;
    }

    .app-strore-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      a.app-strore {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.app-strore-left {
          justify-content: flex-start;
        }

        .bage {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 150px;
          height: 45px;
          margin-bottom: 20px;

          &.google {
            background-image: url("images/google-play-badge-black.png");
            background-position: right;
            margin-right: 10px;
          }

          &.apple {
            justify-content: flex-start;
            background-image: url("images/appstore-black.png");
            background-position: left;
          }
        }
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.paginate {
  margin: 0 auto 40px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;

      &.selected {
        a {
          background-color: rgba($color: #f75e5e, $alpha: 1);
          color: #ffffff;
        }
      }

      &.disabled {
        a {
          cursor: default;
          opacity: 0.3;

          &:hover {
            background-color: transparent;
          }
        }
      }

      a {
        width: 33px;
        height: 33px;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        cursor: pointer;
        color: #444444;
        text-decoration: none;

        transition: all 0.3s;

        &:hover {
          background-color: rgba($color: #f75e5e, $alpha: 0.3);
        }
      }
    }
  }
}


.rs-calendar {
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-color: #f75e5e;
    background-color: #f75e5e;
    border-radius: 0;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid #f75e5e;
    border-radius: 0;
  }
}

.rs-picker-toolbar {
  .rs-picker-toolbar-option {
    color: #f75e5e;
  }

  .rs-picker-toolbar-right-btn-ok {
    border-radius: 0;
    background-color: #f75e5e;
  }
}