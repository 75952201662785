.authorization-layout {
    background-color: #dbdbdb;
    width: 100%;
    height: 100vh;
    display: flex;

    .places-content {
        width: 40%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 76px 48px 57px 95px;

        .loading {
            padding: 38px 0;
        }

        .places-list {
            display: flex;
            height: calc(100% - 124px);
            padding: 33px 0;
            overflow: hidden;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;

                    a {
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #2a2f32;
                        transition: .2s;
                        text-decoration: none;
                        padding: 4px 0;
                        display: inline-block;

                        &:hover {
                            color: #f75e5e;
                            transition: .2s;
                        }
                    }
                }
            }
        }

        .places-caption {
            width: 100%;
            height: 81px;
            overflow: hidden;
            font-size: 33px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.01px;
            color: #2a2f32;
            display: block;
        }

        .places-footer {
            display: block;
            width: 100%;
            background-color: #fff;
            overflow: hidden;

            > div {
                display: inline-block;
                width: 100%;
                text-align: right;
            }

            .google {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 10px;
                margin-top: 4px;

                a {
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #444444;
                    transition: 0.2s;
                    display: flex;
                    width: auto;
                    padding: 0 0 0 4px;

                    &:hover {
                        color: #f75e5e;
                        transition: 0.2s;
                    }
                }
            }

            .places-footer-caption {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
                margin-bottom: 4px;
            }

            a {
                display: inline-block;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
                text-decoration: none;
                transition: .2s;
                width: 100%;

                &:hover {
                    color: #f75e5e;
                    transition: .2s;
                }
            }
        }
    }

    .form-content {
        width: 60%;
        height: 100vh;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        .logo {
            top: 27px;
            left: 27px;
            position: absolute;

            background-color: #2a2f32;
            width: 100px;
            height: 100px;
            text-align: center;
            line-height: 100px;
            border-radius: 50%;

            font-size: 36px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
        }

        .rm-logo {
            display: block;
            width: 288px;
            height: 100px;

            top: 27px;
            right: 27px;
            position: absolute;

            background-image: url("../images/full-logo.png");
            background-position: center;
            background-size: cover;
        }
    }

    .form {
        background-color: #ffffff;
        padding: 27px 32px 19px;
        border-radius: 4px;

        form, .form-m {
            width: 467px;
        }
    }
}
.authorization-layout-mobile {
    .form {
        width: 100%;
        .form-caption {
            font-size: 27px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #2a2f32;
        }

        .form-footer {
            a {
                font-size: 15px;
                text-align: center;
                display: block;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
                padding-bottom: 9px;
            }
            a:last-child {
                display: block;
                text-align: center;
            }
        }
    }

    .form-content {
        width: 100%;
        padding: 22px 27px 18px;
        min-height: calc(100vh - 131px);
    }

    header {
        height: 80px;
        overflow: hidden;
        padding: 14px 27px 14px 18px;
        display: flex;

        .logo {
            width: 45px;
            height: 45px;
            background-color: #2a2f32;
            border-radius: 50%;
            text-align: center;
            
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 45px;
            letter-spacing: normal;
            color: #ffffff;
        }
        .header-caption {
            display: flex;
            width: calc(100% - 45px);
            text-align: right;
            align-items: flex-end;
            justify-content: flex-end;

            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: right;
            color: #000000;
        }
    }

    footer {
        text-align: center;
        height: 50px;
        line-height: 50px;

        a {
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #444444;
            text-decoration: none;
        }

        .google {
            display: inline-block;
            padding: 0 18px 18px;
            line-height: 1.4;
            font-size: 10px;
            color: #444444;
        }
    }

    .confirmation {
        margin-bottom: 18px;
        .caption {
            padding-top: 4px;
            padding-bottom: 9px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2a2f32;
        }

        .sender {
            border-color: rgba(219, 219, 219, 0);
            background-color: rgba(219, 219, 219, 0.3);
        }
    }
}