.default-layout {
    width: 100vw;
    min-width: 1240px;
    overflow: hidden;

    header {
        background-color: #f75e5e;
        padding: 0 45px;
        height: 100px;
        display: flex;
        align-items: center;
        box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.18);

        .caption {
            width: 40%;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.5;
            letter-spacing: normal;
            color: #ffffff;
            text-align: left;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            .rm-logo {
                width: 72px;
                height: 72px;
                display: block;

                background-image: url("../images/shorl-logo.png");
                background-position: center;
                background-size: cover;

                margin-right: 14px;
            }

            .link-to-lk {
                color: #ffffff;
                text-decoration: none;
            }
        }

        .user-info {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                }
            }

            a {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #ffffff;
                text-decoration: none;
                margin-right: 18px;
                transition: 0.2s;
            }

            button {
                cursor: pointer;
                border: 0;
                background-color: transparent;
                padding: 0;
                font-size: 23px;
                color: #ffffff;
                outline: 0;
                transition: 0.2s;
            }

            a:hover,
            button:hover {
                color: rgba(#ffffff, 0.5);
                transition: 0.2s;
            }
        }
    }

    >nav {
        display: flex;
        width: 1240px;
        padding: 0 45px;
        height: 100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                justify-content: flex-start;
            }

            &:last-child {
                justify-content: flex-end;
            }
        }

        a {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.01px;
            color: #2a2f32;
            text-decoration: none;
            transition: 0.2s;

            &:hover {
                color: #f75e5e;
                transition: 0.2s;
            }

            &.active {
                font-size: 20px;
                font-weight: bold;
                transition: 0.2s;
            }
        }
    }

    .content {
        min-height: calc(100vh - 300px);
        display: flex;

        .loading {
            padding: 45px;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .text-buy {
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 40px;

            a {
                color: #575757;
                text-decoration: none;
                border-bottom: 1px dashed #f75e5e;
                transition: .2s;
            }
        }

        .select-wrapper {
            margin: 0 auto 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            select {
                width: 30%;
                border: 1px solid #dbdbdb;
                background-color: #fff;
                height: 33px;
                padding: 0 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
                border-radius: 5px;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    footer {
        margin: 0 50px;
        padding: 0 50px;
        display: flex;
        width: calc(100% - 100px);
        background-color: rgba(#ffffff, 0);
        position: relative;
        z-index: 1;

        >div {
            border-top: 1px solid #818182;
            width: 100%;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &.gray {
            background-color: rgba(#dbdbdb, 0.3);
        }

        .links {
            width: 100%;
            border-top: 1px solid #818182;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #444444;
            transition: 0.2s;

            &.google {
                justify-content: flex-end;

                a {

                    padding: 0 5px;
                }
            }

            a {
                color: #444444;
                text-decoration: none;

                &:hover {
                    color: #f75e5e;
                    transition: 0.2s;
                }
            }
        }
    }
}

.default-mobile-layout {
    width: 100vw;
    overflow-x: hidden;

    header {
        background-color: #ffffff;
        padding: 0 18px;
        height: 36px;
        display: flex;
        align-items: center;

        .caption {
            width: 40%;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #000000;
            text-align: left;

            .link-to-lk {
                color: #000000;
                font-weight: 600;
                text-decoration: none;
                font-size: 12px;
            }
        }

        .user-info {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                }
            }

            a {
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #000000;
                text-decoration: none;
                margin-right: 14px;
                transition: 0.2s;
            }

            button {
                cursor: pointer;
                border: 0;
                background-color: transparent;
                padding: 0;
                font-size: 14px;
                color: #000000;
                outline: 0;
                transition: 0.2s;
            }

            a:hover,
            button:hover {
                color: rgba(#000000, 0.5);
                transition: 0.2s;
            }
        }
    }

    >nav {
        display: flex;
        padding: 0;
        height: 45px;
        margin: 0 auto;

        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &:first-child {
                justify-content: flex-start;
            }

            &:last-child {
                justify-content: flex-end;
            }
        }

        a {
            width: 100%;
            height: 100%;
            border: 1px solid #ffffff;
            background-color: #dbdbdb;
            transition: 0.2s;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30%;

            &.currentOrder {
                background-image: url(../images/navigations/currentOrder.svg);
            }

            &.historyOrder {
                background-image: url(../images/navigations/historyOrder.svg);
            }

            &.profile {
                background-image: url(../images/navigations/profile.svg);
            }

            &.bonusProgram {
                background-image: url(../images/navigations/bonusProgram.svg);
            }

            &.writeOffBonus {
                background-image: url(../images/navigations/writeOffBonus.svg);
                color: #444444;
                background-size: auto;
            }

            &.gift {
                background-image: url(../images/navigations/gift.svg);
                color: #444444;
            }

            &.active {
                background-color: #ffffff;
            }
        }
    }

    .content {
        display: flex;

        .loading {
            padding: 45px;
            display: flex;
            justify-content: center;
            width: 100%;
            height: calc(100vh - 131px);
        }

        .text-buy {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 10px;

            a {
                color: #575757;
                text-decoration: none;
                border-bottom: 1px dashed #f75e5e;
                transition: .2s;
            }
        }

        .select-wrapper {
            width: calc(100% - 50px);
            margin: 0 auto 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;


            select {
                width: 100%;
                border: 1px solid #dbdbdb;
                background-color: #fff;
                height: 25px;
                padding: 0 14px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2f32;
                border-radius: 5px;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    footer {
        margin-top: 20px;
        padding: 0 0 40px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(#ffffff, 0);
        flex-direction: column;
        border-top: 1px solid #818182;

        &.gray {
            background-color: rgba(#dbdbdb, 0.3);
        }

        .google {
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #444444;
            text-align: center;
        }

        a {
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #444444;
            text-decoration: none;
            transition: 0.2s;

            &:hover {
                color: #f75e5e;
                transition: 0.2s;
            }
        }
    }
}