.current-order-empty {
    display: flex;
    flex-direction: column;
    padding: 15px 100px 0;

    width: 1240px;
    margin: 0 auto;

    .header {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;

        .dish {
            width: 373px;
            height: 280px;
            display: block;
            margin: 0 auto 30px;
    
            background-image: url("../images/dish.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .caption {
            font-size: 37px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.01px;
            color: #2a2f32;
            margin-bottom: 10px;
        }

        .signature {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0px;
            color: #2a2f32;
        }
    }

    .places-content {
        margin-bottom: 30px;
    }

    .places {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .place {
            float: left;
            margin: 0 10px 30px;
            position: relative;

            width: calc((100% / 4) - 18px);
            height: 187px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                a {
                    width: calc(100% - 25px);
                    height: calc(100% - 22px);
                    transition: .3s;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
                border: 2px solid #f75e5e;
                border-radius: 35px;
                z-index: 0;
            }

            a {
                font-size: 0;
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 35px;

                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                transition: .3s;
                z-index: 1;
                box-shadow: 0px 5px 9px 1px rgba(34, 60, 80, 0.2);
                
                &.bg-1 {
                    background-image: url("../images/places/empty/1.png");
                }
                &.bg-4 {
                    background-image: url("../images/places/empty/4.png");
                }
                &.bg-6 {
                    background-image: url("../images/places/empty/6.png");
                }
                &.bg-8 {
                    background-image: url("../images/places/empty/8.png");
                }
                &.bg-9 {
                    background-image: url("../images/places/empty/9.png");
                }
                &.bg-10 {
                    background-image: url("../images/places/empty/10.png");
                }
                &.bg-11 {
                    background-image: url("../images/places/empty/11.png");
                }
                &.bg-13 {
                    background-image: url("../images/places/empty/13.png");
                }
                &.bg-20 {
                    background-image: url("../images/places/empty/20.png");
                }
                &.bg-16 {
                    background-image: url("../images/places/empty/16.png");
                }
                
                &.bg-17 {
                    background-image: url("../images/places/empty/17.png");
                }

                &.bg-18 {
                    background-color: #000000;
                    overflow: hidden;
                    position: relative;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        &::before {
                            width: calc(138px - 25px);
                            height: calc(138px - 22px);
                            transition: .3s;
                        }
                    }

                    &::after {
                        content: "";
                        opacity: .6;
                        z-index: 0;

                        position: absolute;
                        top: 0;
                        left: 0;

                        background-image: url("../images/places/extra/bg-18.jpg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;

                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    &::before {
                        content: "";
                        display: block;
                        z-index: 1;

                        width: 124px;
                        height: 124px;
                        background-image: url("../images/places/extra/l-18.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: .3s;
                    }
                }

                &.bg-19 {
                    background-color: #000000;
                    overflow: hidden;
                    position: relative;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        &::before {
                            width: calc(138px - 25px);
                            height: calc(138px - 22px);
                            transition: .3s;
                        }
                    }

                    &::after {
                        content: "";
                        opacity: .6;
                        z-index: 0;

                        position: absolute;
                        top: 0;
                        left: 0;

                        background-image: url("../images/places/extra/bg-19.jpg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;

                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    &::before {
                        content: "";
                        display: block;
                        z-index: 1;

                        width: 124px;
                        height: 124px;
                        background-image: url("../images/places/extra/l-19.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: .3s;
                    }
                }
            }
        }
    }
}

.default-mobile-layout {
    .current-order-empty {
        display: flex;
        padding: 15px 25px 0;
        width: 100%;
        margin: 0;
        flex-direction: column;

        .header {
            margin-bottom: 30px;
    
            .dish {
                width: 100%;
                height: 270px;
                margin: 0 auto;
            }
    
            .caption {
                font-size: 35px;
                line-height: 1;
            }
    
            .signature {
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .places {
       flex-direction: column;

       .place {
            width: calc(100% - 18px);
       }
    }
}